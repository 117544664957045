import './App.css';
import TaskEntry from './components/TaskEntry';

function App() {
  return (
    <div className='bg-black'>
      <TaskEntry />
    </div>
  );
}

export default App;
